import * as React from 'react';

import Cookies, { CookieSetOptions } from 'universal-cookie';

import { LoginResponse, VideoResponse, CookieData } from '../domain/types';

type VideoContextType = {
  loading: boolean;
  data: VideoResponse | null;
  email: string | null;
  login: (address: string) => void;
  register: (address: string, firstName: string, lastName: string, country: string, hasEn: boolean, hasDutch: boolean, language: string) => void;
  isAuthenticated: boolean;
  isNewUser: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  accessCodes: {[key: string]: boolean};
};

const VideoContext = React.createContext<VideoContextType | null>(null);

const cookieOptions: CookieSetOptions = {
  path: '/',
  maxAge: 60 * 60 * 24 * 365,
  sameSite: 'none',
  secure: true,
};

export const VideoProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  
  const [loading, setLoading] = React.useState(false);
  const [isNewUser, setIsNewUser] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState<string | null>(null);
  const [data, setData] = React.useState<VideoResponse | null>(null);
  const [accessCodes, setAccessCodes] = React.useState<{[key: string]: boolean}>({});

  const readCookies = (): CookieData => {
    const cookies = new Cookies();
    const address = cookies.get('alamiretv') ?? null;
    const preview = cookies.get('preview') ?? null;
    let isNewUser = cookies.get('isNewUser') ?? true;

    if (address !== null || preview !== null) {
      isNewUser = false;
    }

    return {
      address,
      preview,
      isNewUser,
    }
  };

  const setCookies = (address: string, preview: boolean) => {
    const cookies = new Cookies();
    cookies.set('alamiretv', address, cookieOptions);
    cookies.set('preview', preview, cookieOptions);
  };

  const setNewUserCookie = (isNewUser: boolean) => {
    const cookies = new Cookies();
    cookies.set('isNewUser', isNewUser, cookieOptions);
  };

  const fetchData = async (lang: 'en' | 'nl' = 'en') => {
    setLoading(true);

    try {
      const response = await fetch(`https://www.alamirefoundation.org/${lang}/api/pages/317/`);
      const responseData = await response.json() as VideoResponse;

      setData(responseData);
      const codes = responseData.codes.reduce<{[key: string]: boolean}>((acc, code) => {
        acc[code.code] = code.expired;
        return acc;
      }, {});
      setAccessCodes(codes);

    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const login = React.useCallback(async (address: string) => {

    const url = 'https://www.alamirefoundation.org/nl/alamiretv/login/';

    try {
      const reponse = await fetch(url, {method: 'POST', body: JSON.stringify({address})});
      const responseData = await reponse.json() as LoginResponse;

      if (responseData.result.success) {
        console.log('logged in');
        setIsAuthenticated(true);
        setEmail(address);
        setCookies(address, responseData.result.preview ?? false);
      }

    } catch (error) {
      console.log('error logging in');
      console.log(error); 
    }
  }, []);

  const register = React.useCallback(async (address: string, firstName: string, lastName: string, country: string, hasEn: boolean, hasDutch: boolean, language: string) => {
    const url = 'https://www.alamirefoundation.org/nl/alamiretv/subscribe/';
    // const url = 'http://localhost:8000/en/alamiretv/subscribe/';

    const body = {
      address,
      first_name: firstName,
      last_name: lastName,
      lang: language,
      country,
      english: hasEn,
      dutch: hasDutch,
    };

    try {
      const reponse = await fetch(url, {method: 'POST', body: JSON.stringify(body)});
      const responseData = await reponse.json() as LoginResponse;

      if (responseData.result.success) {
        console.log('registered');
        setIsAuthenticated(true);
        setEmail(address);
        setCookies(address, responseData.result.preview ?? false);
      } else {
        console.log('not registered');
        console.log(responseData);
      }

    } catch (error) {
      console.log('error registering');
      console.log(error); 
    }
  }, []);

  React.useEffect(() => {
    fetchData();
    const result = readCookies();

    console.log(result);

    // if (result.isNewUser) {
    //   setIsNewUser(true);
    //   setIsAuthenticated(true);
    //   setNewUserCookie(false);
    //   return;
    // }

    if (result.address) {
      login(result.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({
      loading,
      data,
      login,
      register,
      isAuthenticated,
      isNewUser,
      setIsAuthenticated,
      accessCodes,
      email,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loading,
      data,
      login,
      register,
      isAuthenticated,
      isNewUser,
      setIsAuthenticated,
      accessCodes,
      email,
    ],
  );

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export const useVideos = () => {
  const context = React.useContext(VideoContext);
  if (!context) {
    throw new Error('useCheckIn must be used within a CheckInProvider');
  }
  return context;
};
